// SubdomainContext.js
import React, { createContext, useContext, useMemo } from 'react';

const SubdomainContext = createContext();

export const SubdomainProvider = ({ children }) => {
  const subdomain = useMemo(() => {
    const host = window.location.hostname;
    const isStagingEnvironment = host.includes('staging.leasehub.app');
    const isDemoEnvironment = host.includes('demo.leasehub.app');
    let parts = host.split('.');

    console.log(parts, parts.slice(-3).join('.'),parts.slice(-2).join('.') )

    // Adjusted logic to handle known second-level domains such as 'staging.leasehub'
    // Checks for environments like 'city.staging.leasehub.app'
    if (parts.length >= 3 && (isStagingEnvironment || isDemoEnvironment)  && parts[0] !== 'www') {
      // Any subdomain of staging.leasehub.app (except 'www') is considered public
      return parts[0];
    } else if (parts.length === 3 && !isStagingEnvironment && !isDemoEnvironment && parts[0] !== 'www') {
      // Production subdomain (city.leasehub.app)
      return parts[0];
    } else if (host.includes("localhost") && parts.length === 2) {
      // Localhost handling
      return parts[0];
    }
    // Default to no subdomain for other cases
    return false;
  }, []);


  const isPublicSubdomain = useMemo(() => {
    // Adjust the condition to fit your definition of a public subdomain
    // For example, if you want to exclude 'staging' from being public
    return subdomain && subdomain !== 'staging' && subdomain !== 'demo';
  }, [subdomain]);

  console.log(isPublicSubdomain)

  return (
    <SubdomainContext.Provider value={{ subdomain, isPublicSubdomain }}>
      {children}
    </SubdomainContext.Provider>
  );
};

export const useSubdomain = () => useContext(SubdomainContext);
